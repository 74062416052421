@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  src: url(/public/assets/fonts/Roboto-Thin.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  src: url(/public/assets/fonts/Roboto-ThinItalic.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: url(/public/assets/fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: italic;
  src: url(/public/assets/fonts/Roboto-LightItalic.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url(/public/assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
  src: url(/public/assets/fonts/Roboto-Italic.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: medium;
  src: url(/public/assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: bold;
  src: url(/public/assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: url(/public/assets/fonts/Roboto-Black.ttf);
}

.italic {
  font-style: italic;
}

.overflow {
  overflow: hidden;
}

/* ESTILOS NORMALES (para escritorios muy grandes) */

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

.show_in_mobile {
  display: none;
}

.hide_in_mobile {
  display: inline;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  align-items: center;
}

.container {
  width: 100%;
  /* z-index: -1; */
  position: relative;
}

.cont_title {
  width: 60%;
  padding: 3em 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.volver {
  position: fixed;
  bottom: 12px;
  right: 12px;
  background-color: black;
  border-radius: 50px;
  padding: 0.5em;
  cursor: pointer;
}

.volver i {
  color: #f9f9f9;
}

.volver:hover {
  background-color: #fe006e;
}

.container_skills {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 60vh;
  padding: 3.5rem 2rem 2rem;
}

.marquee-container {
  z-index: -1 !important;
}

.cont_title img {
  width: 25%;
}

@media (max-width: 1920px) and (max-height: 1080px) {
  .container_skills {
    padding: 13.5rem 2rem 8rem;
  }
}

@media (max-width: 1440px) and (max-height: 900px) {
  .container_skills {
    padding: 10rem 2rem 8rem;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .container_skills {
    padding: 3rem 2rem 6rem;
  }
}

@media (max-width: 1280px) and (max-height: 960px) {
  .container_skills {
    padding: 2rem 1rem 2rem;
  }
}

/* 
@media (min-width: 992px) and (max-width: 1380px) {
  .container_skills {
    padding: 2rem 1rem 1rem;
  }
} */

@media (max-width: 1024px) {
  .container_skills {
    padding: 0.5rem;
    min-height: auto;
  }
}

@media screen and (max-width: 992px) {
  .container_skills {
    padding: 1rem;
    min-height: 100%;
  }
}

@media screen and (max-width: 800px) {
  .container_skills {
    padding: 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 942px) {
  .cont_title img {
    width: 35%;
  }
}

@media (max-width: 768px) {
  .cont_title img {
    width: 50%;
  }
}

@media (max-width: 444px) {
  .hide_in_mobile {
    display: none;
  }

  .show_in_mobile {
    display: inline;
  }

  .cont_title {
    width: 70%;
  }

  .cont_title img {
    width: 100%;
  }
}

@media (max-width: 332px) {
  .cont_title {
    padding: 1.8em 0;
  }
}
