/* ESTILOS NORMALES (para escritorios muy grandes) */

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.read_more div {
  text-decoration: underline;
  font-weight: 500;
}

.description div {
  text-decoration: underline;
  font-weight: 500;
}

.container_about {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.container_about_info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 4em;
}

.container_menu {
  width: 100%;
  padding: 2em 2em 0em 2em;
}

.cont_left {
  width: 20%;
}

.cont_img_prof {
  width: 100%;
}

.cont_img_prof img {
  width: 100%;
}

.cont_img_info {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
}

.cont_description {
  width: 39%;
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
}

.name {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2em;
  color: #000000;
}

.profession {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.3em;
  margin-top: 0.5em;
  color: #000000;
}

.description {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-top: 2em;
  margin-bottom: 1em;
  color: #000000;
  line-height: 180%;
}

.cont_redes {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1em;
}

.cont_red {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-right: 1.5em;
}

.cont_red img {
  width: 40px;


}

.cont_red img:hover {
  filter: invert(17%) sepia(80%) saturate(7470%) hue-rotate(327deg) brightness(98%) contrast(106%);

}

.container_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.container_buttons button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-right: 1em;
  background-color: #fe006e;
  border: none;
  border-radius: 1em;
  width: 150px;
  padding: 0.5em 0;
  margin-bottom: 1em;
  margin-top: 1em;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 1s ease 0s;
}

.container_buttons button a {
  color: #ffffff;
}

button:hover {
  background-color: #000000;

}

.container {
  width: 100%;
}

.show {
  display: block;
  visibility: visible;
}

.hide {
  display: none;
  visibility: hidden;
}

/* RESPONSIVE  =========================================================================  */

@media (max-width: 1920px) and (max-height: 1080px) {
  .container_about {
    margin-top: 6em;
  }
}

@media (max-width: 1440px) and (max-height: 900px) {
  .container_about {
    margin-top: 3.5em;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .container_about {
    margin-top: 1em;
  }
}
@media (max-width: 1280px) and (max-height: 960px) {
}

@media (max-width: 1512px) {
  .name {
    font-size: 1.7em;
  }

  .profession {
    font-size: 1.2em;
  }

  .description {
    font-size: 0.8em;
  }

  .container_about {
    margin-top: 1em;
  }
}
/* 
@media (max-width: 1212px) {
  .cont_description {
    width: 49%;
  }

  .cont_img_info {
    width: 78%;
  }

  .cont_left {
    width: 25%;
  }
}

@media (max-width: 972px) {
  .cont_img_info {
    width: 80%;
  }

  .cont_description {
    width: 50%;
  }

  .name {
    font-size: 1.3em;
  }

  .profession {
    font-size: 1em;
  }

  .description {
    font-size: 0.8em;
  }

  .container_buttons button {
    font-size: 1em;
  }
  
  .cont_left {
    width: 28%;
  }
}

@media (max-width:612px) {
  .cont_left {
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .cont_redes {
    margin-bottom: 1em;
  }
}

@media (min-width: 769px) and (max-width: 972px) {
  .cont_img_info {
    width: 90%;
  }

  .cont_red p {
    display: none;
  }

  .cont_red {
    margin-right: 1em;
  }

  .container_buttons button {
    width: 156px;
  } */


/* } */
@media (max-width: 820px) {
  .cont_img_info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cont_description {
    width: 80%;
    margin: 0;
    align-items: center;
  }



  .cont_left {
    width: 60%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .cont_redes {
    margin-bottom: 1em;
  }
}

@media (max-width: 444px) {
  .cont_redes div p {
    display: none;
  }

  .cont_description {
    align-items: flex-start;
  }

  .name {
    font-size: 1.6em;
  }
  .profession {
    font-size: 1.2em;
  }

  .container_buttons {
    width: 100%;
    flex-direction: column;
    margin-top: 1em;
  }
  .container_buttons button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .cont_redes {
    width: 100%;
    justify-content: space-between;
  }

  .cont_red {
    margin: 0;
  }

  .cont_description span:hover {
    color: #fe006e;
  }
}

@media (max-width: 390px) {
  .cont_img_info {
    width: 90%;
  }
}

@media (max-width: 332px) {
  .cont_img_info {
    width: 90%;
  }

  .cont_left {
    width: 70%;
  }

  .cont_description {
    width: 85%;
  }
}
