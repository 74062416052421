.container_footer {
  background-color: #fe006e;
  width: 100%;
  color: #f9f9f9;
  padding: 1em 0;
  text-align: center;
}

@media (max-width: 332px) {
  .container_footer {
    font-size: 0.8em;
  }
}
