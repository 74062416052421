.skills_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3.5rem;
  padding: 0 2rem;

}

.skillsModal_container {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.skill_scroll {
  width: 100%;
  margin: 0;
}

.skill_box {
  background: #fafafa;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 120px;
  height: 100px;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  transition: 0.3s ease-in-out;
}

/* @media (min-width: 992px) and (max-width: 1380px) {
  .skills_container {
    padding: 0.5rem;
    margin-top: 1.5rem;
  }
}

@media (max-width: 992px) {
  .skills_container {
    padding: 0;
    margin: 1rem 0;
  }

  .skill_box {
    width: 150px;
    height: 150px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }
} */

@media screen and (max-width: 800px) {
  .skills_container {
    padding: 0.5rem;
  }

  .skill_scroll {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .skill_box {
    width: 135px;
    height: 135px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill_box img {
    height: 40px;
  }

  .skill_box p {
    font-size: 18px;
    margin-top: 1rem;
  }
}
