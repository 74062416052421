.container_portfolio {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #ffe5f0;
  margin-top: 5em;
}

.cont_project_list {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5em;
  margin-top: 2em;
}

/* .filterSelect {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
} */

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 25%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.select select {
  font-family: "Arial";
  display: inline-block;
  width: 200px;
  cursor: pointer;
  padding: 10px 11px;
  outline: 0;
  text-align: center;
  border: 0px hidden #000000;
  border-radius: 23px;
  background-color: #000000;
  color: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1em;
  box-shadow: 10px 13px 54px -8px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 10px 13px 54px -8px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 10px 13px 54px -8px rgba(0, 0, 0, 0.67);
}
.select select::-ms-expand {
  display: none;
}
.select select:hover {
  color: #ffffff;
  background: #fe006e;
}
.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select_arrow {
  position: absolute;
  top: 48px;
  right: 86px;
  width: 0px;
  height: 0px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


/* select {
  border: 1px solid #ffffff;
  padding: 1em;
  color: #ffffff;
  font-size: 1em;
  border-radius: 1.5em;
  margin: 0 1em;
  width: 10rem;
  background-color: #fe006e;
} */

/* RESPONSIVE  =========================================================================  */


@media (max-width: 972px) {
  .cont_project_list {
    width: 90%;
  }
}

@media (min-width: 769px) and (max-width: 890px) {
  .cont_project_list {
    width: 100%;
  }
}

@media (max-width:612px){
  .select {
    width: 60%;
  }
}