.container_contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #ffe5f0;
  margin-top: 5em;
}

.cont_form {
  width: 80%;
  display: flex;
  justify-content: center;
}

.swal2-icon-show {
  background-color: #000 !important;
}



.cont_name_email {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5em;
}

.cont_mess {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

input {
  border: 1px solid #fe006e;
  background-color: #f9f9f9;
  width: 100%;
  height: auto;
  border-radius: 5px;
  /* padding-left: 0.5em; */
  padding: 0.2em;
  font-size: 1em;
}

textarea {
  border: 1px solid #fe006e;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  padding: 0.2em;
  font-size: 1em;
}

label {
  font-family: "Roboto", sans-serif;
  margin: 0.5em 0;
}

.name,
.email {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.name {
  margin-right: 2em;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.contSend {
  display: flex;
}

.contSend button {
  background-color: #fe006e;
  border-radius: 30px;
  border: none;
  padding: 0.5em;
  margin-top: 2em;
  margin-bottom: 5em;
  width: 150px;
  color: #f9f9f9;
  font-weight: 600;
  font-size: 1em;
  transition: background-color 1s ease 0s;
  cursor: pointer;
}

.contSend button:hover {
  background-color: #000;
}

@media (max-width: 1512px) {
  .cont_form {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .cont_form {
    width: 85%;
  }
}

@media (max-width: 972px) {
  .cont_form {
    width: 85%;
  }

  input {
    height: 35px;
    border: 2px solid #fe006e;
  }

  textarea {
    border: 2px solid #fe006e;
  }
}

@media (max-width: 768px) {
  .cont_form {
    width: 80%;
  }

  form {
    width: 100%;
  }
}

@media (max-width: 444px) {
  .cont_form {
    width: 85%;
  }

  .cont_name_email {
    flex-direction: column;
    width: 100%;
  }

  .name {
    margin-right: 0;
  }
}

@media (max-width: 332px) {
  .cont_form {
    width: 85%;
  }
}
