.back_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.container_modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.944);
  height: 80vh;
  width: 80vw;
  padding: 1em;
  display: flex;
  z-index: 1;
  top: 8%;
  right: 8%;
  flex-direction: row;
  box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  overflow-y: scroll;
  border-radius: 10px;
  justify-content: space-between;

}

.each_skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  max-height: 120px;
  /* width: 120px; */
  border-radius: 1em;
  background-color: #fefefe;
  box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 10px 10px 17px -7px rgba(0, 0, 0, 0.48);
  padding: 1em;

}

.each_skill img {
  width: 80px;
}

.title {
  font-size: 0.8em;
}

.close {
  width: 5%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.display {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

}

.view_all {
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.2em;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1em;
  text-align: center;
}

.view_all:hover {
  color: #fe006e;
}

@media (max-width: 712px) {
  .container_modal {
    width: 85%;
    right: 4%;
  }
}

@media (max-width: 444px) {
  .each_skill {
    width: 30%;
    margin: 0.5em;
    min-height: 140px;
  }

  .each_skill img {
    width: 120px;
  }
  .container_modal {
    right: 3%;
  }
}

@media (max-width: 332px) {

  .each_skill img {
    width: 100px;
  }
  .container_modal {
    right: 4%;
    width: 80%;
  }
}
