/* ESTILOS NORMALES (para escritorios muy grandes) */

.container_menu {
  width: 25%;
}

.container_achievements {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 60vh;
  padding: 1rem 2rem 2rem;
}

.container_divs_achievements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 5em 0em;

}

.cont_each_achievement {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 30%;
  background-color: #ffe5f0;
  padding: 1em;
  margin: 1em;
  height: auto;
  border-radius: 25px;
  box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
}

.cont_certificate_achievement {
  display: flex;
  width: 35%;
  padding-right: 2em;
}

.cont_certificate_achievement img {
  width: 100%;
}

.container_info {
  width: 60%;
}

.cont_title_achievement {
  color: #fe006e;
  margin-bottom: 0.4em;
  font-size: 1em;
  font-weight: 600;
}

.cont_place_achievement {
  color: #000;
  margin-bottom: 0.4em;
  font-size: 1em;
  font-weight: 500;
}

.cont_date_achievement {
  color: #000;
  font-size: 1em;
  font-style: italic;
}

@media (max-width: 1512px) {
.cont_title_achievement {
  font-size: 1.3em;
}

.cont_date_achievement {
  font-size: 1em;
}

.cont_place_achievement {
  font-size: 1em;
}
.cont_title_achievement {
  font-size: 1.2em;
}

.container_divs_achievements {
  margin: 0;
}

.cont_each_achievement {
  width: 40%;
}

}

@media (max-width: 1024px) {
  .cont_each_achievement {
    width: 75%;
  }
}

@media (max-width: 972px) {
  .cont_each_achievement {
    width: 70%;
  }
}

@media (min-width: 769px) and (max-width: 942px) {
  .cont_each_achievement {
    width: 100%;
  }

  .container_divs_achievements {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .cont_each_achievement {
    width: 90%;
  }

  .container_divs_achievements {
    width: 100%;
  }
}

@media (max-width: 444px) {
  .cont_each_achievement {
    flex-direction: column;
  }

  .cont_certificate_achievement {
    width: 90%;
    padding: 1em;
  }

  .container_info {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cont_title_achievement p {
    text-align: center;
  }

  .cont_place_achievement p {
    text-align: center;
  }

  .cont_date_achievement p {
    text-align: center;
  }

  .container_divs_achievements {
    margin: 0;
  }
}

@media (max-width: 332px) {
  .container_achievements {
    padding: 0;
  }

  .cont_each_achievement {
    padding: 1em;
  }

  .container_divs_achievements {
    width: 90%;
  }
}
