.container_education {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffe5f0;
  margin-top: 5em;
  /* margin-bottom: 5em; */
  padding-bottom: 5em;
  width: 100%;
}

.cont_info_edu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}

.cont_design,
.cont_dev {
  background-color: #ffffff;
  border-radius: 1em;
  padding: 1em 0em;
  margin: 0.5em;
  height: auto;
  min-height: 175px;
  width: 300px;
  /* max-width: 300px;
  min-width: 300px; */
  /* max-height: 200px; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.cont_design .one {
  font-weight: 400;
  text-align: center;
  margin-top: 1em;
  font-size: 0.8em;
}

.cont_design .two {
  font-weight: 700;
  text-align: center;
  font-size: 1em;
  margin-top: 1em;
}

.cont_design .three {
  font-weight: 300;
  text-align: center;
  font-size: 0.8em;
  margin-top: 1em;
}

.cont_dev .one {
  font-weight: 400;
  text-align: center;
  margin-top: 1em;
  font-size: 0.8em;
}

.cont_dev .two {
  font-weight: 700;
  text-align: center;
  font-size: 1em;
  margin-top: 1em;
}

.cont_dev .three {
  font-weight: 300;
  text-align: center;
  font-size: 0.8em;
  margin-top: 0.8em;
}

/* RESPONSIVE  =========================================================================  */

/* @media (max-width: 1512px) { */
  .cont_info_edu {
    justify-content: center;
  }
  /* .cont_design,
  .cont_dev {
    min-height: 200px;
  } */

  .cont_design div img {
    width: 50px;
  }

  .cont_dev div img {
    width: 50px;
  }

  /* .cont_design .one {
    font-size: 0.8em;
  }

  .cont_dev .one {
    font-size: 0.8em;
  }

  .cont_design .two {
    font-size: 1em;
  }

  .cont_dev .two {
    font-size: 1em;
  }

  .cont_design .three {
    font-size: 0.8em;
  }

  .cont_dev .three {
    font-size: 0.8em;
  } */
/* } */

/* @media (max-width: 1212px) {
  .cont_design,
  .cont_dev {
    min-width: 280px;
    max-width: 280px;
  }
}

@media (max-width: 972px) {
  .cont_design,
  .cont_dev {
    min-width: 300px;
    max-width: 300px;
  }

  .cont_info_edu {
    width: 85%;
  }
}

@media (min-width: 769px) and (max-width: 890px) {
  .cont_info_edu {
    width: 90%;
  }
} */

@media (max-width: 820px) {
  .cont_info_edu {
    flex-wrap: wrap;
  }

  .cont_dev .two {
    font-size: 1.3em;
  }

  .cont_design .two {
    font-size: 1.3em;
  }

  .cont_design,
  .cont_dev {
    padding: 1em;
  }
}

@media (max-width: 444px) {
  .cont_design,
  .cont_dev {
    min-width: 250px;
    max-width: 250px;
  }
}

@media (max-width: 390px) {
  .cont_design .one {
    font-size: 1em;
  }

  .cont_design .two {
    font-size: 1.3em;
  }

  .cont_design .three {
    font-size: 1em;
  }

  .cont_dev .one {
    font-size: 1em;
  }

  .cont_dev .two {
    font-size: 1.3em;
  }

  .cont_dev .three {
    font-size: 1em;
  }

  .container_education {
    margin-top: 2em;
    margin-bottom: 1em;
  }
}
