.container_experience {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.cont_info_exp {
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.cont_each_exp {
  background-color: #ffe5f0;
  width: 20%;
  height: auto;
  min-height: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2em;
  border-radius: 1em;
  margin: 0 1em;
}
.pad_txt {
  padding: 0 1.5em;
}

.plinth {
  background-color: #fe006e;
  text-align: center;
  color: #ffffff;
  padding: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
  margin-top: 2em;
}

.one {
  font-weight: 700;
  text-align: left;
  margin-top: 0.8em;
  font-size: 1em;
  color: #fe006e;
}

.two {
  font-weight: 700;
  text-align: left;
  font-size: 1.3em;
  margin-top: 0.5em;
}

.three {
  font-weight: 300;
  text-align: left;
  font-size: 0.8em;
  margin-top: 1em;
}

/* RESPONSIVE  =========================================================================  */

@media (max-width: 1512px) {

  .cont_each_exp {
    width: 25%;
    height: auto;
    min-height: 375px;
  }

  .cont_info_exp {
    flex-wrap: wrap;
  }

  /* .one {
    font-size: 1em;
  }

  .two {
    font-size: 1.3em;
  }

  .three {
    font-size: 0.8em;
  } */

  .plinth {
    font-size: 0.8em;
  }



}

/* @media (max-width: 1212px) {
  .cont_each_exp {
    width: 25%;
    height: 250px;
    margin-top: 1em;
  }




}

@media (max-width: 972px) {
  .cont_each_exp {
    width: 28%;
    height: 250px;
    margin-top: 1em;
  }
}

@media (min-width: 769px) and (max-width: 972px) {
  .cont_each_exp {
    height: 280px;
  }

  .cont_info_exp {
    width: 100%;
  }
} */

@media (max-width: 820px) {
  .cont_each_exp {
    width: 60%;
    margin-top: 1em;
    margin-bottom: 1em;
    height: auto;
    min-height: auto;
  }

  .cont_info_exp {
    flex-wrap: wrap;
  }

    .one {
    font-size: 1.2em;
  }

  .two {
    font-size: 1.3em;
  }

  .three {
    font-size: 1em;
  }

  .plinth {
    font-size: 1em;
  }
}

@media (max-width:444px) {
  .cont_each_exp {
    width: 70%;
  }
}


@media (max-width: 390px) {


  .one {
    font-size: 1.3em;
  }

  .two {
    font-size: 1.5em;
  }

  .three {
    font-size: 1em;
    margin-top: 1em;
  }

  .cont_each_exp {
    width: 86%;
  }
}
