.container_item_project {
  display: flex;
  width: 315px;
  position: relative;
  text-align: center;
  position: relative;
  margin: 1em 0;
  border-radius: 10px;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 9px 12px 34px -3px rgba(0, 0, 0, 0.66);
}

.title {
  transition: opacity 0.5s linear 0s;
}

.container_item_project:hover .title {
  opacity: 0;
}

.container_item_project:hover .hover_action {
  transform: translate(0%, 40%);
}

.container_item_project:hover .hover_action_tech {
  transform: translate(0%, 260%);
}

.cont_img_title {
  position: relative;
  display: inline-block;
  text-align: center;
}

.cont_img_title img {
  width: 100%;
}

.cont_img_title div {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.42);
  padding: 0.6em 0;
}

.cont_img_title div p {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  padding: 0 1em;
}

.cont_img_title .filter {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.32);
  padding: 0;
}

.cont_buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.cont_buttons div img {
  width: 40px;
  transition: width 0.3s linear 0s;
}

.cont_buttons div a img:hover {
  filter: invert(30%) sepia(96%) saturate(7496%) hue-rotate(325deg)
    brightness(97%) contrast(119%);
  width: 45px;
}

.hover_action {
  background: rgb(33, 33, 33);
  color: rgb(234, 234, 234);
  position: absolute;
  width: 80%;
  height: 120px;
  left: 0;
  top: -55px;
  -webkit-transform: translate(-110%, 40%);
  transform: translate(-100%, 40%);
  padding: 15px;
  border-radius: 0 20px 20px 0;
  transition: -webkit-transform 0.9s;
  transition: transform 0.9s;
  transition: transform 0.9s, -webkit-transform 0.9s;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.8em;
}

.hover_action_tech {
  background: rgb(33, 33, 33);
  color: rgb(234, 234, 234);
  position: absolute;
  width: 90%;
  height: 15px;
  right: 0;
  top: 50px;
  -webkit-transform: translate(100%, 260%);
  transform: translate(100%, 260%);
  padding: 15px;
  border-radius: 20px 0px 0px 20px;
  transition: -webkit-transform 0.9s;
  transition: transform 0.9s;
  transition: transform 0.9s, -webkit-transform 0.9s;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 0.6em; */
}

/* RESPONSIVE  =========================================================================  */

@media (max-width: 1512px) {
  /* .hover_action_tech {
    font-size: 0.8em;
  } */

  /* .cont_img_title div p {
    font-size: 1em;
  } */

  .cont_buttons div img {
    width: 35px;
  }
}

@media (max-width: 768px) {
  .hover_action_tech {
    font-size: 1em;
  }
}

@media (max-width: 412px) {
  .hover_action_tech {
    font-size: 0.5em;
    height: 30px;
    transform: translate(100%, 375%);
    width: 93%;
    padding: 1em;
  }

  .container_item_project:hover .hover_action_tech {
    transform: translate(4%, 375%);
  }

  .hover_action {
    width: 88%;
    transform: translate(-100%, 48%);
    font-size: 0.8em;
    height: 110px;
    padding: 1em;
  }

  .container_item_project:hover .hover_action {
    transform: translate(0%, 48%);
  }

  .cont_buttons div img {
    width: 35px;
  }

  .cont_img_title div p {
    font-size: 1.2em;
  }
}
