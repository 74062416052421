/* ESTILOS NORMALES (para escritorios muy grandes) */

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000000;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0em;
}

.container_home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px; 
  background-color: #ffffff;
  border: #fe006e solid 1px;
  border-radius: 0.5em;
  width: 80px;
  padding: 0.5em 0;
  margin-bottom: 1em;
  margin-top: 1em;
  cursor: pointer;
  color: #000000;
}

.container_up {

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
}

.container_all {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.container_profile {
  width: 45%;
}

.container_profile img {
  width: 100%;
}

.container_txt {
  margin-top: 2em;
}

.cont_hola p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 3em;
  letter-spacing: 1.5px;
  margin: 0;
  padding: 0;
  -webkit-text-stroke: 1px #000000;
  color: transparent;
  stroke: #000000 1px;
}

.cont_soy p {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 3.8em;
  margin: 0;
}

.cont_soy p span {
  color: #fe006e;
}

.cont_anclaje p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  font-size: 1em;
}

.cont_anclaje {
  background-color: #fe006e;
  display: inline-block;
  padding: 0.3em;
  margin-bottom: 0.5em;
}

.cont_description p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1em;
  padding-bottom: 0.5em;
}

.container_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container_buttons button {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1em;
  background-color: #ffffff;
  border: #fe006e solid 1px;
  border-radius: 0.5em;
  width: 45%;
  padding: 0.5em 0;
  margin-bottom: 1em;
  margin-top: 1em;
  cursor: pointer;
  color: #000000;
}

.container_buttons a {
  width: 45%;
}

.container_buttons a button {
  width: 100%;
  display: block;
}

.container_buttons button:hover {
  background-color: #fe006e;
}

.container_footer {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
}

.cont_block_foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cont_block_foot img {
  width: 50px;
}

.cont_block_foot img:hover {
  border: #fe006e 1px solid;
}

.line img {
  width: 10px;
}

.container_logo {
  display: flex;
  width: 20%;
  justify-content: flex-start;
  margin-left: 3em;
}



/* RESPONSIVE  =========================================================================  */



@media (max-width: 1212px) {
  .container_logo {
    margin-left: 1em;
  }

  .container_all {
    width: 80%;
  }

  /* .cont_soy p {
    font-size: 3.5em;
  }

  .cont_anclaje p {
    font-size: 1.5em;
  }

  .cont_description p {
    font-size: 1.4em;
  }

  .container_buttons button {
    font-size: 1.5em;
  } */


}

/* @media (max-width: 972px) {
  .cont_hola p {
    font-size: 2.5em;
  }

  .cont_soy p {
    font-size: 3.5em;
  }

  .cont_anclaje p {
    font-size: 1.5em;
  }

  .cont_description p {
    font-size: 1.4em;
  }

  .container_buttons button {
    font-size: 1.5em;
    margin: 0.5em 0;
  }

  .container_buttons {
    justify-content: space-between;
  }
}

@media (max-width: 612px) {
  .container_home {
    align-items: center;

  }

  .container_all {
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  .container_up {
    width: 80%;
    margin: 0;
  }

  .container_buttons {
    flex-direction: row;
  }

  .container_buttons button {
    margin-right: 1em;
  }

  .container_profile {
    width: 70%;
    margin-top: 1.5em;
    margin-bottom: 0em;
  }

  .container_logo {
    margin: 0;
    width: 30%;
  }

  .container_footer {
    margin-bottom: 0;
  }

  .cont_block_foot {
    width: 45%;
    justify-content: space-evenly;
  }

  .cont_block_foot img {
    width: 40px;
  }

  .cont_soy p {
    padding: 0;
  }
}

@media (max-width: 444px) {
  .cont_soy p {
    font-size: 3.5em;
  }

  .cont_anclaje p {
    font-size: 1.3em;
  }

  .container_up {
    width: 85%;
  }

  .cont_description p {
    font-size: 1.4em;
  }

  .container_buttons button {
    font-size: 1.2em;
    width: 170px;
  }

  .container_profile {
    width: 85%;
    margin-top: 2.5em;
  }

  .container_all {
    width: 90%;
  }
  .container_profile {
    display: none;
  }
}

@media (max-width: 332px) {
  .container_buttons {
    width: 100%;
    flex-direction: column;
  }

  .container_buttons button {
    width: 100%;
    margin-right: 0;
  }
  .container_buttons a {
    width: 100%;
    margin-left: 0;
  }

  .cont_soy p {
    font-size: 3em;
  }

  .cont_anclaje p {
    font-size: 0.9em;
  }

  .cont_description p {
    font-size: 1.1em;
  }

  .container_all {
    width: 100%;
  }


} */
