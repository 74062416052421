.container_logo_nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
  -webkit-box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
  -moz-box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
}

.container_nav {
  display: flex;
}

.container_nav div {
  margin: 0 1em;
}

.language {
  margin: 0 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #313131;
  font-size: 0.8em;
  background-color: #ffffff;
  border: #fe006e solid 1px;
  border-radius: 0.5em;
  width: auto;
  padding: 0.5em;
}

.language i {
  font-size: small;
}

.container_nav_mob {
  display: none;
}

.icon_menu {
  display: none;
}

.container_nav div p {
  box-shadow: inset 0 0 0 0 #fe006e;
  color: #000;
  padding: 0.5em;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  font-size: 1em;
}

.container_nav div p:hover {
  box-shadow: inset 150px 0 0 0 #fe006e;
  color: white;
  padding: 0.5em;
}

@media (max-width: 820px) {
  .container_nav {
    display: none;
  }

  .language {
    display: none;
  }

  .language_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fe006e;
    border: #ffffff solid 1px;
    border-radius: 0.5em;
    width: 80px;
    padding: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;
    cursor: pointer;
    color: #000000;
  }

  .language_mobile p {
    margin: 0 !important;
  }

  .container_nav_mob {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fe006e;
    height: 100vh;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    padding: 40px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    z-index: 1;

  }

  .icon_menu {
    display: block;
  }

  .container_logo_nav {
    justify-content: space-between;
    padding: 1em;
    width: auto;
    /* width: 95%; */
    /* margin: 0; */
  }

  .container_nav_mob p {
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }

  .back_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .container_link_mob {
    z-index: 1;
  }

  .logo_mob {
    width: 45%;
  }

  .logo_mob img {
    width: 100%;
  }

  .cont_down_logo {
    display: flex;
  }

  .close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .close i {
    color: #fff;

  }

  .icon_menu i {
    font-size: 45px;
  }
  .container_logo {
    width: 50%;
  }
}



/* .container_logo_nav {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin-top: 0.5em;
  box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
  -webkit-box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
  -moz-box-shadow: -1px 10px 13px -4px rgba(135,133,133,0.76);
}

.container_nav_mob {
  display: none;
}

.icon_menu {
  display: none;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: #fe006e solid 1px;
  border-radius: 0.5em;
  width: auto;
  padding: 0.5em;
  margin-bottom: 1em;
  margin-top: 1em;
  cursor: pointer;
  color: #313131;
  font-size: 0.8em;
}

.language i {
  font-size: small;
}

.container_nav {
  display: flex;
  width: 50%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

}

.container_nav div p {
  box-shadow: inset 0 0 0 0 #fe006e;
  color: #000;
  padding: 0.5em;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  font-size: 1em;
}

.container_nav div p:hover {
  box-shadow: inset 100px 0 0 0 #fe006e;
  color: white;
  padding: 0.5em;
}

.container_logo {
  width: 14%;
}

@media (max-width: 1212px) {
.container_nav {
  width: 60%;
}


@media (max-width:871px){
  .container_nav div p {
    font-size: 0.8em;
  }
}


}

@media (max-width: 712px) {
  .container_nav {
    width: 75%;
  }

  .container_nav div p {
    font-size: 0.8em;
  }
}

@media (max-width: 612px) {
  .container_nav {
    display: none;
  }

  .language {
    display: none;
  }

  .language_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fe006e;
    border: #ffffff solid 1px;
    border-radius: 0.5em;
    width: 80px;
    padding: 0.5em 0;
    margin-bottom: 1em;
    margin-top: 1em;
    cursor: pointer;
    color: #000000;
  }

  .language_mobile p {
    margin: 0 !important;
  }

  .container_nav_mob {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fe006e;
    height: 100vh;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    padding: 40px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    z-index: 1;

  }

  .icon_menu {
    display: block;
  }

  .container_logo_nav {
    justify-content: space-between;
    padding: 1em;
    width: 95%;
    margin: 0;
  }

  .container_nav_mob p {
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }

  .back_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .container_link_mob {
    z-index: 1;
  }

  .logo_mob {
    width: 45%;
  }

  .logo_mob img {
    width: 100%;
  }

  .cont_down_logo {
    display: flex;
  }

  .close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .close i {
    color: #fff;

  }

  .icon_menu i {
    font-size: 45px;
  }
  .container_logo {
    width: 30%;
  }
}

@media (max-width: 444px) {

  .container_logo {
    width: 40%;
  }

}

@media (max-width: 332px) {

  .container_logo {
    width: 45%;
  }

}

@media (max-width: 280px) {
.container_logo_nav {
  padding: 0.5em;
}
} */
